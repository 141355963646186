@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap);
/* Material Icons Font (for MD theme) */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(../../static/media/MaterialIcons-Regular.e79bfd88.eot);
    src: local('Material Icons'),
         local('MaterialIcons-Regular'),
         url(../../static/media/MaterialIcons-Regular.570eb838.woff2) format('woff2'),
         url(../../static/media/MaterialIcons-Regular.012cf6a1.woff) format('woff'),
         url(../../static/media/MaterialIcons-Regular.a37b0c01.ttf) format('truetype');
  }
  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
  }
  
  /* Framework7 Icons Font (for iOS theme) */
  @font-face {
    font-family: 'Framework7 Icons';
    font-style: normal;
    font-weight: 400;
    src: url(../../static/media/Framework7Icons-Regular.f30e0292.eot);
    src: url(../../static/media/Framework7Icons-Regular.9393ad14.woff2) format("woff2"),
         url(../../static/media/Framework7Icons-Regular.8f897db6.woff) format("woff"),
         url(../../static/media/Framework7Icons-Regular.1b6b2c3e.ttf) format("truetype");
  }
  .f7-icons {
    font-family: 'Framework7 Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 28px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
    text-align: center;
  }
  
.color-orange {
  --f7-theme-color: #FDC40C;
  --f7-theme-color-rgb: 253, 196, 12;
  --f7-theme-color-shade: #deaa02;
  --f7-theme-color-tint: #fdce34;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.item-multiline .item-title {
  word-break: break-all;
  overflow: visible;
  white-space: normal;
}

.status-timeline {
  margin-left: 15px;
  font-size: 14px;
  color: black;
  font-family: 'Source Sans Pro', sans-serif;
}

.main-section-title {
  padding-left: 16px;
  font-size: 34px;
  font-weight: bold;
  color: #043170;
  font-family: 'Source Sans Pro', sans-serif
}

.main-section-title .navbar-inner .sliding .navbar-inner-centered-title {
  justify-content: center;
}

.side-section-title {
  padding-left: 16px;
  font-weight: bold;
  font-family: 'Source Sans Pro', sans-serif;
}

.side-button-title {
  color: #3980FF;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
}

#group-button {
  color: #3980FF;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  margin-bottom: 30px;
}

.group-title {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 34px;
  font-weight: bold;
}

.machine .item-title {
  font-weight: bold;
  color: #002E6E;
  font-size: 19px;
  font-family: 'Source Sans Pro', sans-serif;
}

.machine .item-subtitle {
  margin: auto 0 2px 5px;
  font-weight: bold;
  color: #707070;
  font-size: 13px;
  font-family: 'Source Sans Pro', sans-serif;
}

.machine .item-content {
  padding: 3px;
}

.media-list .item-link .item-inner, li.media-item .item-link .item-inner .machine {
  padding: 3px;
}

/* più è alto il valore più il cerchio delle immagini è piccolo */

.row .col-28 {
  --f7-cols-per-row: 4;
}

.shift-title {
  font-weight: bold;
  color: #002E6E;
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
}

.shift-subtitle {
  font-weight: normal;
  color: #002E6E;
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif;
}

.goal-title {
  font-size: 11px;
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  color: #AAAAAA;
}

.settings-list .item-title {
  font-family: 'Source Sans Pro', sans-serif;
  color: #707070;
  font-size: 14px;
}

.settings-list .input-with-value {
  font-family: 'Source Sans Pro', sans-serif;
  color: #000000;
  font-size: 17px;
}

.side-col {
  width: 65px;
  align-self: center;
}

.center-col {
  align-self: center;
  line-height: 13px;
}

.historical-title {
  color: #707070;
  margin-top: 10px;
}
